import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import newYorkImage from './images/location_newyork.jpg';
import LAImage from './images/location_losangeles.jpg';
import SFImage from './images/location_sanfrancisco.jpg';
import SeattleImage from './images/location_seattle.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'New York',
          newYorkImage,
          '?address=New%20York%2C%20New%20York&bounds=41.2391317%2C-73.446683%2C40.2391317%2C-74.446683&origin=40.7391317%2C-74.946683'
        )}
        {locationLink(
          'San Francisco',
          SFImage,
          '?address=San%20Francisco%2C%20California&bounds=38.257815%2C-122.0076403%2C37.257815%2C-123.0076403&origin=37.757815%2C-123.5076403'
        )}
      </div>
      <div className={css.locations}>
        {locationLink(
          'Los Angeles',
          LAImage,
          '?address=Los%20Angeles%2C%20California&bounds=34.161440999758%2C-118.121305008073%2C33.9018913203336%2C-118.521456965901&origin=34.0%2C-118.3'
        )}
        {locationLink(
          'Seattle',
          SeattleImage,
          '?address=Seattle%2C%20Washington&bounds=47.7779392908564%2C-122.216605992108%2C47.3403950185547%2C-122.441233019046&origin=47.55%2C-122.3'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
